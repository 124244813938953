<route>
{
  "meta": {
    "auth": "checkReview"
  }
}
</route>

<template>
  <div>
    <el-card class="container">
      <div class="title">评价信息</div>
      <div class="item">
        <span class="desc">用户昵称</span><span class="info">{{ list.memberNickname }}</span>
      </div>

      <div class="item">
        <span class="desc">用户头像</span>
        <img class="img" :src="list.memberIcon | thumbSmall" v-if="list.memberIcon" />
      </div>
      <div class="item">
        <span class="desc">评价时间</span><span class="info">{{ list.createTime | formatTime }}</span>
      </div>

      <div class="item">
        <span class="desc">评价类型</span
        ><span class="info">
          {{ list.anonymous === 1 ? '匿名评价' : '正常评价' }}
        </span>
      </div>
      <div class="item">
        <span class="desc">状态</span
        ><span class="info" v-if="$route.query.type != 'express'">
          {{ list.showStatus === 0 ? '隐藏' : '显示' }}
        </span>
        <span class="info" v-else>
          {{ list.showStatus ? '隐藏' : '显示' }}
        </span>
      </div>
      <template v-if="list.deleteCause">
        <div class="item">
          <span class="desc">删除原因</span
          ><span class="info">
            {{ list.deleteCause }}
          </span>
        </div>
      </template>

      <div class="item">
        <span class="desc">总分数</span
        ><span class="info">
          <el-rate class="rate" v-model="list.allStar" disabled></el-rate>
        </span>
      </div>
      <div class="item" v-if="$route.query.type != 'express'">
        <span class="desc">门店名称</span
        ><span class="info">
          {{ list.storeTitle }}
        </span>
      </div>

      <div class="rate_list">
        <div class="item" v-for="item in list.reviewTypeList" :key="item.id">
          <span class="desc">{{ item.reviewTypeTitle }}</span>
          <span class="info">
            <el-rate class="rate" v-model="item.star" disabled></el-rate>
          </span>
        </div>
      </div>

      <div class="item">
        <span class="desc">评价内容</span><span class="info">{{ list.content }}</span>
      </div>
      <div class="item">
        <span class="desc">评价图片</span>
        <span class="info" v-if="list.reviewPhoto === undefined || list.reviewPhoto.length === 0">没有评价图片</span>

        <span v-else>
          <img class="img" v-for="(item, index) in list.reviewPhoto" :src="item | thumbMedium" :key="index" />
        </span>
      </div>
      <template v-if="+list.productId !== 0">
        <div class="title">商品信息</div>
        <div class="item">
          <span class="desc">商品名称</span><span class="info">{{ list.skuTitle }}</span>
        </div>

        <div class="item">
          <span class="desc">商品图片</span
          ><span class="info">
            <img class="img" :src="list.skuPhoto | thumbMedium" />
          </span>
        </div>
        <div class="item">
          <span class="desc">商品编号</span><span class="info">{{ list.productId }}</span>
        </div>

        <div class="item">
          <span class="desc">订单编号</span><span class="info">{{ list.orderId }}</span>
        </div>
      </template>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: {}
    }
  },
  created() {
    if (this.$route.query.type == 'express') {
      this.getExpressDetail()
    } else {
      this.getDetail()
    }
  },
  methods: {
    getExpressDetail() {
      this.$http
        .get('/boom-center-admin-service/sysAdmin/bm/order/review/' + this.$route.query.id)
        .then(res => {
          this.list = {
            ...res,
            memberIcon: res.memberInfo.avatar,
            memberNickname: res.memberInfo.memberName,
            allStar: res.totalScore,
            skuTitle: res.productInfo.productName,
            skuPhoto: res.productInfo.productImage,
            productId: res.productInfo.productId,
            orderId: res.orderInfo.bmOrderId,
            reviewPhoto: res.reviewImages,
            showStatus: res.hide
          }
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    getDetail() {
      this.$http
        .get('boom-mix-biz-service/sysAdmin/userReview/' + this.$route.query.id)
        .then(res => {
          this.list = { reviewImages: [], ...res }

          console.log(this.list)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  margin: @container-margin;
  padding-left: 20px;

  .title {
    .secondTitle;
    margin: 20px 0;
  }

  .item {
    margin: 10px 0;
    display: flex;
    align-items: center;
    font-size: 14px;
    .rate {
      display: inline-block;
    }

    .desc {
      display: inline-block;
      width: 120px;
    }

    .info {
      color: @color-assist;
    }
    .img {
      width: 100px;
      height: 100px;
      margin: 0 5px;
      border-radius: 8px;
    }
  }
}
</style>
